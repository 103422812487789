.tooltip {
  background: white;
  pointer-events: none;
  border-radius: 8px;
  padding: 5px 10px;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.1);
  z-index: 999;

  composes: element from 'styles/text.css';
  font-size: 10px;
  line-height: 14px;
  text-align: center;
}

.tooltip.large {
  padding: 12px;
}

.arrow {
  position: absolute;
  background: white;
  border-radius: 2px;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  z-index: 1000;
  bottom: -4px;
}
