.container {
  border: 1px solid #DBDBDB;
  display: flex;
  align-items: center;
  composes: element from 'styles/text.css';
  font-size: 12px;
  line-height: 24px;
  padding: 13px 20px;
  cursor: default;
  color: black;
  width: 100%;
}

.container:hover {
  border-color: #b2b2b2;
}

.container.selected {
  border-color: black;
}

.container.disabled {
  cursor: not-allowed;
}
.container.disabled * {
  opacity: 0.3;
}
.container.disabled .disabledOverlay {
  opacity: 1;
}

.label {
  flex: 1;
}
