.container {
    display: inline-block;
    position: relative;
    pointer-events: none;
    flex: 1;
    margin-top: 20px;
    margin-bottom: 10px;
}

.image {
    position: absolute;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    pointer-events: none;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.markers {
    position: absolute;
    overflow: visible !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.marker * {
    pointer-events: auto;
}

.markerLabel {
    composes: element from 'styles/text.css';
    user-select: none;
    cursor: pointer;
    fill: #999999;
}

.markerLine {
    stroke: #999999;
    cursor: pointer;
    stroke-linecap: round;
}

.markerPoint {
    fill: #b2b2b2;
    stroke: white;
    stroke-width: 6px;
    cursor: pointer;
}

/* Selected */

.marker.markerSelected .markerLabel {
    fill: var(--color-accent);
}

.marker.markerSelected .markerLine {
    stroke: var(--color-accent);
}

.marker.markerSelected .markerPoint {
    fill: white;
    stroke: var(--color-accent);
}

/* Hover */

.marker:hover .markerLabel {
    fill: black;
}

.marker:hover .markerLine {
    stroke: black;
}

.marker:hover .markerPoint {
    fill: white;
    stroke: black;
}

/* Active / clicked */

.marker:active .markerLabel {
    fill: var(--color-active);
}

.marker:active .markerLine {
    stroke: var(--color-active);
}

.marker:active .markerPoint {
    fill: white;
    stroke: var(--color-active);
}

@media print {
    .image {
        background-size: cover;
    }
}
