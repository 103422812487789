@import './fonts.css';

.header-1 {
  font-family: Georgia;
  font-weight: bold;
  font-size: 34px;
  color: black;
}

.header-2 {
  font-family: Georgia;
  font-weight: bold;
  font-size: 28px;
  color: #8B0000;
}

.subtitle {
  font-family: CenturyGothic;
  font-size: 16px;
  color: #666666;
}

.text-1 {
  font-family: CenturyGothic;
  font-size: 16px;
  color: black;
}

.text-2 {
  font-family: CenturyGothic;
  font-size: 16px;
  color: #999999;
}

.text-3 {
  font-family: CenturyGothic;
  font-size: 16px;
  color: #8B0000;
}

.element {
  font-family: CenturyGothic;
  font-size: 14px;
  color: black;
}

.button-label {
  font-family: CenturyGothic;
  font-weight: bold;
  font-size: 16px;
  color: #8B0000;
}

@media (max-width: 760px) {
  .header-1 {
    font-size: 28px;
  }

  .header-2 {
    font-size: 22px;
  }

  .subtitle {
    font-size: 14px;
  }

  .text-1 {
    font-size: 14px;
  }

  .text-2 {
    font-size: 13px;
  }

  .text-3 {
    font-size: 13px;
  }
}
