* {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important; /* Firefox 48 – 96 */
    print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
}

.container {
    margin: 0 45px;
    margin-bottom: 100px;
}

.title {
    composes: header-1 from 'styles/text.css';
    text-align: center;
    margin-top: 50px;
}

.header {
    display: flex;
    align-items: flex-start;
    margin-top: 50px;
    padding-bottom: 22px;
    border-bottom: 1px solid #dbdbdb;
}

.headerSide {
    flex: 1;
}

.headerTitle {
    font-family: CenturyGothic;
    font-weight: bold;
    font-size: 28px;
    color: black;
}

.headerOptions {
    composes: text-1 from 'styles/text.css';
    margin-top: 4px;
}

.headerButton {
    width: 350px;
}

.content {
    display: flex;
}

.contentColumn {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.contentRow {
    display: flex;
    justify-self: stretch;
}

.optionTitle {
    composes: element from 'styles/text.css';
    color: #999999;
    margin-top: 26px;
}

.optionList {
    padding-left: 10px;
}

.optionValue {
    composes: text-1 from 'styles/text.css';
}

.logo {
    width: 93px;
    height: 46px;
}

.previewSmall {
    min-height: 300px;
}

.dateLabel {
    composes: element from 'styles/text.css';
    color: #999999;
    margin-left: 10px;
}

.printOnly {
    display: none;
}

.priceSeparator {
    margin-top: 26px;
}

.priceContent {
    flex: 1;
}

.priceContainer {
    display: flex;
    justify-content: stretch;
}

.priceLabel {
    text-align: right;
    composes: element from 'styles/text.css';
    color: #999999;
}

.priceValue {
    text-align: right;
    composes: text-3 from 'styles/text.css';
    font-size: 22px;
}

.closeButton {
    position: absolute;
    left: 38px;
    appearance: none;
    background: none;
    border: none;
}

@media print {
    @page {
        size: A4 portrait;
        margin: 1cm 0 1cm 0;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    .screenOnly {
        display: none;
    }

    .printOnly {
        display: initial;
    }

    .container {
        display: flex;
        height: 100%;
        overflow-y: hidden;
        flex-direction: column;
        justify-content: space-between;
    }

    .header {
        margin-top: 0;
        align-items: flex-end;
    }

    .footer {
        display: flex;
        justify-content: space-between;
        padding-top: 15px;
        border-top: 1px solid #dbdbdb;
    }

    .preview {
        flex: 2
    }

    .previewSmall {
        min-height: 280px;
        margin: 0 !important;
    }
}

@media (max-width: 480px) {
    .container {
        margin: 0 15px;
    }

    .header {
        flex-direction: column;
    }

    .headerButton {
        margin-top: 20px;
        align-self: stretch;
        width: auto;
    }

    .content {
        flex-direction: column;
        margin-bottom: 50px;
    }

    .previewSmall {
        min-height: 120px;
    }

    .content .contentColumn:nth-child(1) {
        min-height: 350px;
        margin: -40px 0;
    }
}
