.image {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 4px;
  max-height: 65px;
}

.confirmedOverlay {
  position: absolute;
  top: 0;
  left: 10px;
  right: 10px;
  bottom: 4px;

  background: url('assets/confirmed-check.svg') no-repeat center;
}
