.container {
  display: flex;
  align-items: center;
}

.label {
  flex: 1;
  composes: text-2 from 'styles/text.css';
  font-size: 10px;
  line-height: 20px;
  padding-left: 5px;
}
