.container {
  display: flex;
  align-items: center;
}

.input {
  appearance: none;
  margin-right: 12px;
  width: 24px;
  height: 24px;
  border: 1px solid #999999;
  box-sizing: border-box;
  flex-shrink: 0;
}

.label {
  composes: text-2 from 'styles/text.css';
  color: black;
}

.container:hover .input,
.container:active .input,
.container .input:checked {
  border-color: var(--color-accent);
}

.input:checked:after {
  display: inline-block;
  content: url('assets/checkmark-filled.svg');
  text-align: center;
  padding-top: 5px;
  width: 22px;
}
