.button {
  --button-color: #8b0000;

  appearance: none;
  padding: 12px 32px;
  box-sizing: border-box;
  background-color: white;
  border: 2px solid var(--button-color);
  color: var(--button-color);

  composes: button-label from 'styles/text.css';
  text-overflow: ellipsis;
  overflow: hidden;
}

.button:hover {
  --button-color: var(--color-highlight);
}

.button:active {
  --button-color: var(--color-active);
}

.filled {
  background-color: var(--button-color);
  color: white;
}

.disabled {
  opacity: 0.5;
}
