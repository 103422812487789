.button {
  appearance: none;
  width: 50px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  border: 2px solid white;
  border-radius: 41px;
  opacity: 0.4;
}

.button:hover {
  opacity: 1;
}

.button:active {
  border: 2px solid #eeeeee;
  outline: 2px solid var(--color-active);
}

.selected {
  border: 2px solid #eeeeee;
  outline: 2px solid var(--color-accent);
  opacity: 1;
}

@media (max-width: 768px) {
  .button {
    width: 54px;
    height: 54px;
  }
}

@media (max-width: 480px) {
  .button {
    width: 54px;
    height: 54px;
  }
}
