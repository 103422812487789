.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  composes: header-2 from 'styles/text.css';
  margin-top: 48px;
  color: black;
}

.actions {
  display: flex;
  margin-top: 56px;
  align-self: stretch;
}

.actions > * {
  flex: 1;
}

.actions > * + * {
  margin-left: 30px;
}
