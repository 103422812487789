@font-face {
  font-family: Georgia;
  src: url(assets/Georgia.ttf);
}

@font-face {
  font-family: Georgia;
  src: url(assets/GeorgiaBold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: CenturyGothic;
  src: url(assets/CenturyGothic.ttf);
}

@font-face {
  font-family: CenturyGothic;
  src: url(assets/CenturyGothicBold.ttf);
  font-weight: bold;
}
