.list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 15px;
}

.section {
  composes: subtitle from 'styles/text.css';
  display: flex;
  align-items: center;
  width: 100%;
}

.sectionLine {
  flex: 1;
  margin-left: 4px;
  height: 1px;
  background-color: #DBDBDB;
}

@media (max-width: 480px) {
  .list {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .section {
    justify-content: center;
    text-align: center;
    max-width: 60px;
  }

  .sectionLine {
    display: none;
  }
}
