.container {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4px;
  user-select: none;
  width: 90px;
  box-sizing: border-box;
  /* This margin keeps box height same when hovered or selected */
  margin-top: 2px;
}

.container.narrow {
  width: 72px;
}

.container.wide {
  align-items: flex-start;
  width: auto;
}

.container:hover:not(.disabled) {
  border-top: 1px solid #b2b2b2;
  margin-top: 1px;
}

.container:active:not(.disabled) {
  border-top: 1px solid var(--color-active);
  margin-top: 1px;
}

.container.disabled {
  cursor: not-allowed;
}
.container.disabled > * {
  opacity: 0.3;
}
.container.disabled .disabledIcon {
  opacity: 1;
}

.container.selected {
  border-top: 2px solid var(--color-accent);
  margin-top: 0;
}

.content {
  position: relative;
}

.disabledIcon {
  position: absolute;
  top: 4px;
  right: 4px;
}

.label {
  composes: element from 'styles/text.css';
  text-align: center;
  padding: 0 9px;
}

.container.wide .label {
  text-align: left;
}
