.spinner {
  display: inline-block;
  width: 72px;
  height: 72px;
  border: 3px solid var(--color-accent);
  border-radius: 50%;
  border-top-color: #fff;
  box-sizing: border-box;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
