.container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.label {
  composes: element from 'styles/text.css';
  margin-bottom: 8px;
}

.input {
  composes: text-2 from 'styles/text.css';

  border: 1px solid #dbdbdb;
  line-height: 24px;
  padding: 12px 16px;
  border-radius: 0;
  position: relative;

  background: url('assets/chevron-down.svg') no-repeat scroll;
  background-position: center right 17px;

  color: black;
}

.input.open {
  background: url('assets/chevron-up.svg') no-repeat scroll;
  background-position: center right 17px;
}

.input:placeholder {
  color: #999999;
}

.input:hover {
  border: 1px solid #666666;
}

.input.open,
.input:active,
.input:focus {
  border: 1px solid black;
  outline: none;
}

.inputWrapper {
  position: relative;
}

.dropdown {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  background-color: white;
  border: 1px solid black;
  border-top: none;
  visibility: hidden;
  max-height: 300px;
  overflow-x: auto;
}

.dropdown.open {
  visibility: visible;
  z-index: 1;
}

.dropdownItem {
  composes: text-2 from 'styles/text.css';
  cursor: pointer;
  margin-left: 16px;
  line-height: 36px;
  color: black;
}

.dropdownItem.highlighted {
  color: var(--color-highlight);
}

@media (max-width: 480px) {
  .label {
    margin-bottom: 6px;
  }

  .input {
    line-height: 22px;
    padding: 8px 16px;
  }
}
