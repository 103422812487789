.container {
  display: flex;
  flex: 1;
  border: 1px solid #dbdbdb;
  min-width: 0;
}

.previewContent {
  position: relative;
  flex: 8;
  min-width: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-size: cover;
  overflow: hidden;
}

.previewControls {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 20px;
  padding-top: 0;
}

.previewControls > * {
  flex: 1;
}

.viewToggle {
  display: flex;
  justify-content: center;
  flex: 3;
  gap: 8px;
}

.pickerContent {
  display: flex;
  flex: 4;
  flex-direction: column;
  min-width: 0;
}

.picker {
  flex: 1;
  margin-top: 30px;
  padding: 0 30px;
  overflow-y: auto;
  position: relative;
}

.actions {
  display: flex;
  flex-direction: column;
  padding: 0 30px 30px 30px;
  box-shadow: 0px -4px 10px rgba(196, 196, 196, 0.3);
}

.actionsRow {
  display: flex;
  margin-top: 18px;
  gap: 16px;
}

.actionButton {
  flex: 1;
  min-width: 0;
}

.hoodToggle {
  align-self: center;
}

.hoodTooltip {
  width: 230px;
  font-weight: normal;
}

.hoodTooltip img {
  width: 76px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .previewContent {
    height: 380px;
    flex: none;
  }

  .actions {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2px);
    margin: 0 -40px;
    position: sticky;
    z-index: 2;
    bottom: 0;
  }
}

@media (max-width: 480px) {
  .container {
    flex-direction: column;
  }

  .checkbox {
    top: 30px;
    right: -5px;
    left: auto;
    bottom: auto;
    width: 90px;
  }

  .previewContent {
    padding: 10px;
    padding-top: 50px;
    margin-bottom: -20px;
    min-height: 340px;
  }

  .previewContent.withHood {
    min-height: 600px;
  }
}
