.container {
  display: flex;
  flex-direction: column;
  padding: 50px 40px;
  padding-bottom: 0;
  height: 100%;
  box-sizing: border-box;
}

.title {
  display: flex;
  justify-content: center;
  composes: header-1 from 'styles/text.css';
  text-align: center;
  margin-top: 0;
  margin-bottom: 40px;
}

.resetLink {
  composes: text-3 from 'styles/text.css';
  position: absolute;
  line-height: 38px;
  text-decoration: none;
  font-weight: normal;
  right: 40px;
}

.form {
  display: flex;
  min-height: 0;
  flex: 1;
}

.fieldsColumn {
  overflow-y: auto;
  padding-right: 20px;
  padding-bottom: 40px;
  flex: 2;
}

.viewerColumn {
  margin-left: 16px;
  flex: 1;
}

.fieldGroup {
  display: flex;
  margin-bottom: 24px;
}

.fieldGroup > * {
  flex: 1;
}

.fieldGroup > * + * {
  margin-left: 30px;
}

.formActions {
  display: flex;
  justify-content: center;
}

.actionButton {
  width: 50%;
}

.actionButton + .actionButton {
  margin-left: 30px;
}

.categoryLabel {
  composes: text-1 from 'styles/text.css';
  margin-bottom: 12px;
  font-weight: 700;
}

.checkboxGroup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 30px;
  margin-top: 24px;
  margin-bottom: 30px;
}

.formSpacer {
  margin-top: 48px;
}

.brochureCheckbox {
  margin-bottom: 12px;
}

.formHint {
  composes: element from 'styles/text.css';
  color: #666666;
  margin-bottom: 12px;
}

.formWarningHint {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  composes: element from 'styles/text.css';
  color: #666666;
  margin-bottom: 20px;
  border: 1px solid #8B000080;
  border-radius: 8px;
  background: #8B00000D;
  padding: 16px;
}

.warningIcon {
  width: 20px;
  height: 20px;
  margin-right: 16px;
}

.rangeBox {
  display: flex;
  aspect-ratio: 1;
  flex: 1;
}

.viewToggle {
  display: flex;
  flex: 1;
  justify-content: center;
  gap: 20px;
}

.viewButton {
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.viewButton.viewButtonSelected {
  border-bottom: 2px solid var(--color-accent);
}

.viewIcon {
  width: 62px;
  height: 62px;
}

.viewButton:hover .viewIcon {
  opacity: 0.5;
}

.viewFootnote {
  display: flex;
  flex: 1;
  justify-content: center;
}

@media (max-width: 768px) {
  .container {
    padding: 50px 39px;
  }

  .viewerColumn {
    position: absolute;
    top: 115px;
    right: 39px;
  }
}

@media (max-width: 480px) {
  .title {
    margin-bottom: 40px;
  }

  .container {
    padding: 50px 15px;
  }

  .viewerColumn {
    display: none;
  }

  .fieldGroup {
    flex-direction: column;
  }

  .fieldGroup > * + * {
    margin-left: 0;
    margin-top: 18px;
  }

  .formActions {
    flex-direction: column;
  }

  .actionButton + .actionButton {
    margin-left: 0;
    margin-top: 16px;
  }
}
