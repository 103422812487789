.logo {
  width: 100%;
  height: 37px;
  margin: 16px 0;
  object-fit: contain;
}

.title {
  composes: header-1 from 'styles/text.css';
  font-size: 26px;
  text-align: center;
  margin: 0;
  margin-bottom: 24px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-left: 45px;
  margin-right: 45px;
}

.closeButton {
  justify-self: center;
  color: var(--color-accent) !important;
}

.language {
  line-height: 17px !important;
  padding: 6px !important;
  composes: element from 'styles/text.css';
}

.collapsibleBox {
  display: flex;
  align-items: flex-start;
  margin: 0 20%;
  margin-bottom: 32px;
}

.collapsibleBox.collapsed {
  margin-bottom: 22px;
}

.collapseButton {
  display: inline-block;
  appearance: none;
  border: none;
  background: none;
  line-height: 0;
  padding: 0;
}

.collapseContent {
  margin-left: 16px;
}

.expandLink {
  composes: element from 'styles/text.css';
  color: var(--color-accent);
  text-decoration: none;
  line-height: 24px;
  transition: opacity 0.5s linear;
  opacity: 0;
}

.collapsibleBox.collapsed .expandLink {
  opacity: 1;
}

.promo {
  composes: text-1 from 'styles/text.css';
  font-size: 14px;
  color: #666666;
  margin: 0;
}

.promoList {
  composes: text-1 from 'styles/text.css';
  font-size: 14px;
  color: #666666;
  padding-left: 22px;
}

.promoLink {
  color: black;
}

.content {
  display: flex;
  height: 740px;
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 115px;
  min-width: 0;
}

:global(body) {
  padding: 0 !important;
}

@media print {
  .screenOnly {
    display: none;
  }
}

@media (max-width: 768px) {
  .content {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 0;
    height: auto;
  }

  .collapsibleBox {
    margin: 0 40px;
  }
}
