.container {
  border: 1px solid #dbdbdb;
  display: flex;
  align-items: center;
  composes: element from 'styles/text.css';
  font-size: 12px;
  line-height: 24px;
  padding: 5px 20px;
  padding-right: 5px;
  cursor: pointer;
  color: black;
}

.container:hover {
  border-color: #b2b2b2;
}

.label {
  margin-left: 10px;
  flex: 1;
}

.cover {
  height: 80px;
}
