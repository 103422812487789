.label {
  composes: element from 'styles/text.css';

  display: flex;
  flex-direction: column;
}

.input {
  composes: text-2 from 'styles/text.css';

  border: 1px solid #dbdbdb;
  margin-top: 8px;
  line-height: 24px;
  padding: 12px 16px;
  border-radius: 0;

  color: black;
}

.input:hover {
  border: 1px solid #666666;
}

.input:active,
.input:focus {
  border: 1px solid black;
  outline: none;
}

.textArea {
  height: 130px;
}

@media (max-width: 480px) {
  .input {
    margin-top: 6px;
    line-height: 22px;
    padding: 8px 16px;
  }

  .textArea {
    height: 94px;
  }
}
