
.logo {
  width: 150px;
  height: 67px;
}

.error {
  color: #d32f2f;
}

