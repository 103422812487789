.link {
  composes: text-1 from 'styles/text.css';
  text-decoration: none;
  opacity: 0.4;
}

.link:hover {
  opacity: 1;
  color: var(--color-highlight);
}

.link:active {
  color: var(--color-active);
}

.navigational {
  text-decoration: underline;
  opacity: 1;
}

.selected {
  color: var(--color-accent);
  border-bottom: 1px solid var(--color-accent);
  font-weight: bold;
  opacity: 1;
}
