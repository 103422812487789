.container {
}

.header {
  position: sticky;
  z-index: 1;
  top: 0;

  display: flex;
  align-items: flex-start;
  background-color: white;
  padding-bottom: 12px;
  margin-bottom: 16px;
  border-bottom: 2px solid var(--color-accent);
}

.container.expanded .header {
  border-bottom: 2px solid #DBDBDB;
}

.headerColumn {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  flex: 1;
}

.headerLabel {
  composes: header-2 from 'styles/text.css';
  color: black;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 4px;
  flex: 1;
}

.hideSelection .headerLabel {
  margin-top: 10px;
  margin-bottom: 10px;
}

.container.expandable .headerLabel {
  color: #8B0000;
}

.expandButton {
  visibility: hidden;
}

.container.expandable .expandButton {
  visibility: visible;
}

.warning {
  align-self: stretch;
}

.selectionLabel {
  composes: element from 'styles/text.css';
}

.selectionLabel.hasSelection {
  color: #666666;
}

.hideSelection .selectionLabel {
  display: none;
}

.container.expanded .content {
  margin-bottom: 16px;
}
